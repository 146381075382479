<template>
  <div class="order_detail_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">订单详情</div>
      <div>
        <van-icon name="arrow-left" size="24" color="#fff" />
      </div>
    </div>
    <div class="order_status" v-if="orderDetail.status == 1">
      <div>
        <div class="icon-box">
          <van-icon style="pointer-events:none;width:.7rem;height:.7rem;"  :src="require('../../assets/svg/待付款icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
        </div>
        <span style="font-size:16px;color:#333;margin-left:5px;">待付款</span>
      </div>
      <div style="font-size:12px;padding-top:10px;">请在 <span style="color:var(--fc--);">{{orderDetail.add_time + 7200 | formatDate}}</span> 前完成支付，超时订单自动取消。</div>
    </div>
    <div class="order_status" v-if="orderDetail.status == 2 && goods_recieve_type == '1'">
      <div>
        <div class="icon-box">
          <van-icon style="pointer-events:none;width:.7rem;height:.7rem;"  :src="require('../../assets/svg/待备货icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
        </div>
        <van-icon v-if="false" :name="require('../../assets/images/icon/待付款@2x.png')" size="22" />
        <span style="font-size:16px;color:#333;margin-left:5px;">待发货</span>
      </div>
      <div style="font-size:12px;padding-top:10px;">商家尽快为您发货，请耐心等待。</div>
    </div>
    <div class="order_status" v-if="orderDetail.status == 2 && goods_recieve_type == '2'">
      <div>
        <div class="icon-box">
          <van-icon style="pointer-events:none;width:.7rem;height:.7rem;"  :src="require('../../assets/svg/待备货icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
        </div>
        <span style="font-size:16px;color:#333;margin-left:5px;">待备货</span>
      </div>
      <div style="font-size:12px;padding-top:10px;">等待商家备货中，备货完成前往自提点</div>
    </div>
    <div class="order_status" v-if="orderDetail.status == 3  && goods_recieve_type == '1'">
      <div>
        <div class="icon-box">
          <van-icon style="pointer-events:none;width:.7rem;height:.7rem;"  :src="require('../../assets/svg/物流icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
        </div>
        <span style="font-size:16px;color:#333;margin-left:5px;">待收货</span>
      </div>
      <div style="font-size:12px;padding-top:10px;">商家已发货，请耐心等待。</div>
    </div>
    <div class="order_status" v-if="orderDetail.status == 3  && goods_recieve_type == '2'">
      <div>
        <div class="icon-box">
          <van-icon style="pointer-events:none;width:.7rem;height:.7rem;"  :src="require('../../assets/svg/物流icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
        </div>
        <span style="font-size:16px;color:#333;margin-left:5px;">待自提</span>
      </div>
      <div style="font-size:12px;padding-top:10px;">商家已完成备货，请前往自提点取货。</div>
    </div>
    <div class="order_status" v-if="orderDetail.status == 4">
      <div>
        <div class="icon-box">
          <van-icon style="pointer-events:none;width:.7rem;height:.7rem;"  :src="require('../../assets/svg/完成icon.svg')" size="1.875rem"  tag="embed" type="image/svg+xml" />
        </div>
        <span style="font-size:16px;color:#333;margin-left:5px;">已签收</span>
      </div>
      <div style="font-size:12px;padding-top:10px;">快打开包裹看看吧。</div>
    </div>
    <div v-if="(orderDetail.status == 3 || orderDetail.status == 4) && goods_recieve_type == '1'">
      <div class="bar"></div>
      <div class="order_address">
        <div style="color:#333;font-weight:550;">物流信息</div>
        <div class="address_info">
          <span>快递公司</span>
          <span>{{orderDetail.order_aftersale.express_name}}</span>
        </div>
        <div class="address_info">
          <span>快递单号</span>
          <span>{{orderDetail.order_aftersale.express_number}}</span>
        </div>
      </div>
    </div>
    <div v-if="goods_recieve_type == '1'">
      <div class="bar"></div>
      <div class="order_address">
        <div style="color:#333;font-weight:550;">收货地址</div>
        <div class="address_info">
          <span>{{orderDetail.order_address.name}}</span>
          <span>{{orderDetail.order_address.tel.slice(0,3) + '****' + orderDetail.order_address.tel.slice(7,12)}}</span>
        </div>
        <div style="color:#666;">
          {{address}}
        </div>
      </div>
    </div>
    <div v-if="goods_recieve_type == '2'">
      <div class="bar"></div>
      <div class="order_address">
        <div style="color:#333;font-weight:550;">自提地址</div>
        <div class="address_info">
          <span>{{orderDetail.store_info.name}}</span>
          <span>{{orderDetail.store_info.mobile}}</span>
        </div>
        <div style="color:#666;">
          {{zitiAddress}}
        </div>
      </div>
    </div>
    <div class="bar"></div>
    <div class="goods">
      <div style="padding:10px 15px;color:#333;font-weight:550;">商品信息</div>
      <div class="goods_detail" v-for="(item,index) in orderDetail.items" :key="index">
        <div class="detail_img">
          <img alt style="width:100%;object-fit:cover;" :src="item.images" />
        </div>
        <div style="width:100%;display: flex;
                        flex-direction: column;
                        justify-content: space-between;">
          <div class="detail_title">
            <div style="color:#333;font-weight:550;">{{item.title}}</div>
            <div style="color:#F6736E;">￥{{item.original_price}}</div>
          </div>
          <div class="detail_title" style="margin-top:-0.5em;">
            <div style="font-size:12px;color:#999;">颜色：金色</div>
            <div style="font-size:12px;color:#999;">共{{item.count}}件</div>
          </div>
          <div class="detail_btn">
            <div style="font-size:14px;color:#999;">
              总
              <span style="font-size:16px;color:var(--fc--);">￥{{item.total_price}}</span>
            </div>
            <div>
              <div class="buy_btn">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bar"></div>
    <div class="order_info">
      <div>订单编号：{{orderDetail.order_id}}</div>
      <div>下单时间：{{orderDetail.add_time | formatDate}}</div>
      <div v-if="orderDetail.status == 3 || orderDetail.status == 4">发货时间：{{orderDetail.order_aftersale.delivery_time | formatDate}}</div>
    </div>
    <div class="bar" style="height:1px;"></div>
    <div class="order_price">
      <div>订单金额</div>
      <div>￥{{orderDetail.total_price}}</div>
    </div>
    <div class="bar"></div>
    <div v-if="orderDetail.status == 1">
      <div class="pay_mode">
        <div style="font-weight:550;">支付方式</div>
        <div class="mode-item" @click="wechat = true">
          <div>
          <van-icon style="pointer-events:none;width:2.5rem;height:2.5rem;"  :src="require('../../assets/svg/微信icon.svg')"   tag="embed" type="image/svg+xml" />
          </div>
          <div>微信</div>
          <div class="check_box">
           <van-checkbox v-model="wechat" checked-color="#F5746E"></van-checkbox>
          </div>
        </div>
        <div class="mode-item" @click="zhifubao = true">
          <div>
          <van-icon style="pointer-events:none;width:2.5rem;height:2.5rem;"  :src="require('../../assets/svg/支付宝icon.svg')"   tag="embed" type="image/svg+xml" />
          </div>
          <div>支付宝</div>
          <div class="check_box">
            <van-checkbox v-model="zhifubao" checked-color="#F5746E"></van-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="buy_bar">
      <div class="buy_bar_icon" @click="$emit('callCustomer')">
        <van-icon :src="require('../../assets/svg/客服icon.svg')" style="width:1.5625rem;height:1.5625rem;pointer-events:none;" tag="embed" />
        <span style="font-size:12px;color:#333;">客服</span>
      </div>
      <div class="buy_bar_icon" v-if="false">
        <van-icon name="shopping-cart-o" size="20" />
        <span style="font-size:8px;">购物车</span>
      </div>
      <div style="display:flex">
        <div class="buy_bar_btn remove" @click="delOrder">取消订单</div>
        <div class="buy_bar_btn buy" style="margin-left:10px;" v-if="orderDetail.status == 1" @click="toPay">去支付</div>
        <div class="buy_bar_btn buy" style="margin-left:10px;" v-if="orderDetail.status == 3" @click="complateOrder">
          确认收货</div>
      </div>
    </div>
    <div class="loading-mask" v-show="isLoading">
      <van-loading type="spinner" >
        {{loadingMsg}}
      </van-loading>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        orderDetail: {
          order_address:{
            name:'',
            tel:''
          }
        },
        zhifubao: false,
        wechat: true,
        goods_recieve_type: 0,
        isLoading:true,
        loadingMsg:'加载中...'
      };
    },
    async created() {
      this.goods_recieve_type = this.$route.query.goods_recieve_type
      try{
        await this.getOrderDetail();
        this.isLoading  = false
      } catch(e){
        this.loadingMsg  = '网络错误'
      }
      
    },
    watch: {
      wechat(val) {
        if (val) {
          this.zhifubao = false;
        } else if (!this.zhifubao) {
          this.wechat = true;
        }
      },
      zhifubao(val) {
        if (val) {
          this.wechat = false;
        } else if (!this.wechat) {
          this.zhifubao = true;
        }
      }
    },
    computed: {
      address() {
        let obj = this.orderDetail ? this.orderDetail.order_address : false
        if (!obj) {
          return ' '
        }
        return `${obj.province_name}  ${obj.city_name}  ${obj.county_name}  ${obj.address}`
      },
      zitiAddress(){
        let obj = this.orderDetail ? this.orderDetail.store_info : false
        if (!obj) {
          return ' '
        }
        return `${obj.province}${obj.city}${obj.area}${obj.address}`
      }
    },
    filters: {
      formatDate(val) {
        let date = new Date(Number(val + '000'))
        let y = date.getFullYear()
        let m = addZero(date.getMonth() + 1)
        let d = addZero(date.getDate())
        let h = addZero(date.getHours())
        let min = addZero(date.getMinutes())
        let s = addZero(date.getSeconds())

        function addZero(a) {
          if (a < 10) {
            return '0' + a
          }
          return a
        }
        return `${y}-${m}-${d} ${h}:${min}:${s}`
      }
    },
    methods: {
      async complateOrder() {
        try {
          await this.$dialog.confirm({
            title: '确认收货',
            message: '请确认是否已经拿到货物'
          })
          let { data: res } = await this.$http.post("/api/receiveGood", {
          order_id: this.$route.params.id
        });
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        //this.$toast.success('取消成功')
        this.$router.go(-1)
        } catch (e) {
          //
        }

        
      },
      toPay() {
        if (this.zhifubao) {
          return this.zfbPay()
        }
        if (this.wechat) {
          return this.wxPay()
        }
      },
      async wxPay() {
        let {
          data: res
        } = await this.$http.post("/api/repay", {
          order_id: this.orderDetail.order_id,
          pay_type: 1
        });
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        // return console.log(res)
        let url = window.location
        let redirect_url = url.origin + '/' + url.search + '#/paystatus/' + res.data.orderNo
        let type = this.goods_recieve_type
        await this.$router.push('/paystatus/' + res.data.orderNo +'?goods_recieve_type=' + type + '&orderId=' + res.data.orderId)
        //this.$router.push('/paystatus/' + res.data.orderNo)
        //+ '&redirect_url=' + encodeURI(redirect_url)
        //return (window.location.href = res.data.mwebUrl);
        // 创建form拉起支付
        let form = document.createElement('form')
        document.body.appendChild(form)
        form.method = 'post'
        form.action = res.data.mwebUrl
        form.submit()
        document.body.removeChild(form)
      },
      async zfbPay() {
        let {
          data: res
        } = await this.$http.post("/api/repay", {
          order_id: this.orderDetail.order_id,
          pay_type: 2
        });
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        //return console.log()
        // let url = window.location
        // let redirect_url = url.origin + '/' + url.search + '#/paystatus/' + res.data.orderNo
        //this.$router.push('/paystatus/' + res.data.orderNo)
        let type = this.goods_recieve_type
        await this.$router.push('/paystatus/' + res.data.orderNo +'?goods_recieve_type=' + type + '&orderId=' + res.data.orderId)
        //+ '&redirect_url=' + encodeURI(redirect_url)
        //return (window.location.href = res.data.mwebUrl);
        // 创建form拉起支付
        let form = document.createElement('form')
        document.body.appendChild(form)
        form.method = 'post'
        form.action = res.data.mwebUrl
        form.submit()
        document.body.removeChild(form)
      },
      async getOrderDetail() {
        let {
          data: res
        } = await this.$http.post("/api/queryOrder", {
          order_id: this.$route.params.id
        });
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        this.orderDetail = res.data;
        console.log(res.data)
      },
      async delOrder() {
        if (this.orderDetail.status !== 1) {
          return this.$toast.fail('请联系客服退货')
        }
        let {
          data: res
        } = await this.$http.post("/api/cancelOrder", {
          order_id: this.$route.params.id
        });
        if (res.statusCode !== 0) {
          return this.$toast.fail(res.msg);
        }
        this.$router.go(-1)
        this.$toast.success('取消成功')
      }
    }
  };
</script>

<style lang="less" scoped>
.icon-box{
  width: 1.25rem;
  height: 1.25rem;
  background: var(--fc--);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
}
.loading-mask{
  position: absolute;
  width: 100%;
  height: calc(100vh - 3.71rem - 50px);
  top:3.71rem ;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
  filter: blur(0.5);
}
  .pay_mode {
    text-align: left;
    padding: 15px;
    background-color: #fff;

    .mode-item {
      margin-top: 30px;
      font-size: 15px;
      display: flex;
      align-items: center;
      position: relative;

      >div {
        display: flex;
        align-items: center;
        margin-left: 10px;
      }

      div:first-child {
        margin-left: 0;
      }

      .check_box {
        position: absolute;
        right: 0;
      }
    }
  }

  .buy_bar {
    position: fixed;
    z-index: 444;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .buy_bar_icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .buy_bar_btn {
      width: 5.1875rem;
      height: 2rem;
      
      color: #fff;
      border-radius: 2rem;
      
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 0.32px;
    }

    .remove {
      background-color: #fff;
      color: var(--fc--);
      border: 1px solid var(--fc--);
    }

    .buy {
      background-color: #f6736e;
    }
  }

  .goods_detail {
    padding: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;

    .detail_img {
      width: 70px;
      height: 70px;
      background-color: #eee;
      flex-shrink: 0;
      margin-right: 20px;
      display: flex;
      align-items: center;

      img {
        height: 100%;
      }
    }

    .detail_title {
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      //padding-right: 20px;
    }

    .detail_btn {
      display: flex;
      justify-content: space-between;
    }
  }

  .order_detail_box {
    height: 100vh;
    position: relative;
    padding: 5.5rem 0 3.125rem 0;
    background-color: #f9f9f9;

    .order_status {
      padding: 10px 20px;
      background-color: #fff;

      >div:not(:last-child) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 550;
      }
    }

    .bar {
      height: 10px;
      background-color: #f9f9f9;
    }

    .order_address {
      padding: 15px;
      background-color: #fff;
      font-size: 14px;
      color: #666;
      text-align: left;

      .address_info {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        color: #333;
      }
    }

    .goods {
      display: flex;
      flex-direction: column;
      background-color: #fff;

      >div {
        display: flex;
        justify-content: space-between;
      }
    }

    .order_info {
      padding: 6px 15px;
      background-color: #fff;

      >div {
        padding: 6px 0;
        display: flex;
        justify-content: space-between;
        text-align: left;
        color: #666;
      }
    }

    .order_price {
      padding: 10px 15px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      color: #333;

      div:last-child {
        color: #ff3000;
        font-size: 16px;
      }
    }
  }
</style>